<template>
  <div
    class="conductive-plastic-tools px-8 py-10 container"
    style="max-width: 1150px;margin:0 auto;"
  >
    <div class="flex flex-wrap">
      <div class="w-full md:w-1/2 mb-6 md:pr-2">
        <TitleComponent title="Conductive Plastic Tools" />
        <Paragraph
          ><p>
            External stimulus such as electrostatic discharge (ESD), impact, or
            friction to energetic material may result in initiation of the
            material. Unexpected initiation can have severe consequences
            including loss of equipment, damage to facilities, and even injury
            or death of personnel. Whether its cleaning, transporting, storing,
            or processing energetic material, the types of tools and equipment
            used should be evaluated carefully.
          </p>
          <p>
            Safety Management Services (SMS) offers a wide range of conductive
            plastic hand tools to aid companies in decreasing the risk of
            ignition of energetic material. The conductive nature of these tools
            allows them to be easily grounded directly through the personnel
            handling them or through a ground wire if needed. The carbon
            impregnated in the tools helps to retain conductivity during use,
            resulting in static buildup being kept to a minimum and prevention
            of ESD. The chemical-resistant polypropylene construction also
            reduces the likelihood of an impact or friction related initiation
            due to the low compressive yield strength of the tooling. The
            conductive plastic tools and can improve efficiency by reducing the
            need for frequent replacement.
          </p>
          <p>
            SMS ensures our tools are compliant with standards set by the
            Electrostatic Discharge Association (ESDA) by being conductive to
            less than 10<sup>5</sup> ohms (ESD TR53-01-18). Our ergonomic design
            also aids workers and personnel in handling energetic material in
            the safest and easiest way possible.
          </p>
          <p>
            In addition to having the right tools it is also important to
            understand the nature of the energetic materials. Understanding
            sensitivities and reactivities of materials can help a company make
            wise decisions on the types of tools necessary for the job. SMS can
            provide
            <router-link to="/risk-management/combustible-dust"
              >dust testing</router-link
            >,
            <router-link to="/testing/sensitivity-testing/material-sensitivity"
              >impact sensitivity testing, friction sensitivity testing, and ESD
              sensitivity testing</router-link
            >
            amongst others to best advise your organization on how they could
            improve the safety of their processes.
          </p>
        </Paragraph>
      </div>
      <div class="w-full md:w-1/2 mb-6 md:pl-2">
        <img src="@/assets/Resources/Blog/Brush-and-Scoop-min.png" />
        <img src="@/assets/Resources/Blog/Spatulas-min.png" />
      </div>
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";
import Paragraph from "@/components/Paragraph";

export default {
  metaInfo: {
    title: "Conductive Plastic Tools",
    titleTemplate: `%s | Safety Management Services, Inc.`,
    meta: [
      {
        name: "description",
        content:
          "External stimulus such as electrostatic discharge (ESD), impact, or friction to energetic material may result in initiation of the material and severe consequences. SMS offers a wide range of conductive plastic hand tools to decrease the risk of ignition of energetic material during handling. These tools are compliant with ESD TR53-01-18."
      }
    ]
  },
  components: {
    TitleComponent,
    Paragraph
  }
};
</script>

<style lang="scss">
.conductive-plastic-tools {
  a {
    color: #aa222c;
  }
}
</style>
